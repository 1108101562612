import React, { useContext, useState, useRef, useEffect } from 'react';
import { Dropdown, DropdownItem, SendMessageWrapper } from './styled';
import { ButtonHashtag, TextFieldStyled, TypographyStyled, TextFieldTablet } from './styled';
import { ChatsContext } from 'src/context/chats';
import { messagesActions } from 'src/context/messages';
import { Tags } from 'src/api/assignments/models';
import { PortalContext } from 'src/context/portal';
import { ChatType } from 'src/api/common/models';
import { TTags } from 'src/api/messages/models';
import { Button, Stack, Typography } from '@mui/material';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { ReactComponent as SendMessageIcon } from 'src/icons/send-message.svg';
import { MAX_MESSAGE_LENGTH } from '../TestRequest/consts';

const hashtagsInitial: Tags[] = ['billing', 'clinical', 'clerical'];

const hashtagForSend = (item: Tags) => {
  const dtoToModel = {
    billing: TTags.Billing,
    clinical: TTags.Medical,
    clerical: TTags.ClericalOrGeneral,
  };
  return dtoToModel[item];
};

export const SendMessage: React.FC = () => {
  const [value, setValue] = useState<string>('');
  const [dropdownActive, setDropdownActive] = useState(false);
  const [hashtags, setHashtags] = useState<Tags[]>(hashtagsInitial);
  const { activeChat, typeActiveChat } = useContext(ChatsContext);
  const { patientId } = useContext(PortalContext);
  const size = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);

  const lastTagReg = new RegExp(/@\w*$/gm);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const valueMessage = e.target.value;
    const targetValue =
      valueMessage.length < MAX_MESSAGE_LENGTH ? valueMessage : valueMessage.slice(0, MAX_MESSAGE_LENGTH);
    setValue(targetValue);
    if (lastTagReg.test(targetValue)) {
      setDropdownActive(true);
      const lastTagValue = targetValue.match(lastTagReg);
      if (lastTagValue) setHashtags(hashtagsInitial.filter(tag => tag.includes(lastTagValue[0].replace(/^@/, ''))));
    } else if (!lastTagReg.test(targetValue) && dropdownActive) {
      setDropdownActive(false);
    }
  };

  const handleClickHashtag = (e: React.MouseEvent<HTMLButtonElement>) => {
    const textContent = e.currentTarget?.textContent || '';
    setValue(prev => `${prev} ${textContent}`);
  };

  const handleClickDropdown = (e: React.MouseEvent<HTMLElement>) => {
    ref.current?.focus();
    const textContent = e.currentTarget?.textContent ? `@${e.currentTarget.textContent} ` : '';
    setValue(prev => prev.replace(lastTagReg, textContent));
    setDropdownActive(false);
  };

  useEffect(() => {
    setValue('');
  }, [activeChat]);

  const sendMessage = () => {
    const tags = hashtagsInitial.filter(hashtag => value.includes(`@${hashtag}`)).map(item => hashtagForSend(item));
    const messageSend = {
      Body: value,
      conversationId: activeChat || undefined,
      tags: tags,
      contactId: patientId && !activeChat ? patientId : undefined,
      conversationType: !activeChat && patientId ? ChatType.DoctorPatient : undefined,
    };
    void messagesActions?.sendMessage(messageSend, typeActiveChat);
    setValue('');
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && value) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <SendMessageWrapper>
      {typeActiveChat === ChatType.Laboratory && (
        <TypographyStyled variant="label14Medium" color="grey.600">
          Add tag:
          {hashtagsInitial.map(hashtag => (
            <ButtonHashtag key={hashtag} sx={{ p: 0, ml: '8px' }} variant="text" onClick={handleClickHashtag}>
              @{hashtag}
            </ButtonHashtag>
          ))}
        </TypographyStyled>
      )}
      <Stack direction="row" alignItems="end">
        {size.isTabletSize || size.isMobileSize ? (
          <>
            <TextFieldTablet
              inputRef={ref}
              onKeyDown={handleOnKeyDown}
              maxRows={5}
              size="small"
              multiline
              value={value}
              onChange={handleChange}
              fullWidth
              placeholder="Your message"
            />
            {size.isTabletSize && (
              <Button
                variant="contained"
                onClick={sendMessage}
                color="secondary"
                sx={{ marginLeft: '6px', width: '42px', minWidth: '42px', height: '42px', padding: '0 0' }}
              >
                <SendMessageIcon />
              </Button>
            )}
          </>
        ) : (
          <Stack width="100%" gap="2px">
            <TextFieldStyled
              inputRef={ref}
              onKeyDown={handleOnKeyDown}
              size="medium"
              maxRows={5}
              multiline
              value={value}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder="Your message"
            />
            <Stack direction="row" justifyContent="flex-end" width="100%">
              <Typography color="grey.700" variant="label12Medium" lineHeight="18px">
                {value.length}/{MAX_MESSAGE_LENGTH}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>

      {size.isMobileSize && (
        <Button variant="contained" fullWidth sx={{ marginTop: '20px' }} onClick={sendMessage} color="secondary">
          Send
        </Button>
      )}
      {dropdownActive && (
        <Dropdown>
          {hashtags.map(hashtag => (
            <DropdownItem onClick={handleClickDropdown} key={hashtag} tabIndex={0}>
              {hashtag}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </SendMessageWrapper>
  );
};
