import { AssignmentStatus } from 'src/api/assignments/models';
import { theme } from 'src/theme';
import { Status } from './Task.styled';

type TaskStatusProps = {
  status?: AssignmentStatus;
  isMobile?: boolean;
  isTablet?: boolean;
  fromCurrentModule: boolean;
  actor?: string;
};

const TaskStatus: React.FC<TaskStatusProps> = ({ status, isMobile, isTablet, fromCurrentModule, actor }) => {
  const withActor = `by ${actor}`;
  const getStatusColor = () => {
    if (status === AssignmentStatus.Incomplete) return theme.palette.grey[100];
    if (status === AssignmentStatus.Completed) return theme.palette.success.dark;
    return theme.palette.error.dark;
  };

  const getStatusText = () => {
    if (status === AssignmentStatus.Incomplete) return 'Unresolved';
    if (status === AssignmentStatus.Completed) return `Resolved ${!isTablet ? withActor : ''}`;
    return `Rejected ${!isTablet ? withActor : ''}`;
  };

  return !isMobile ? (
    <Status module={fromCurrentModule ? 1 : 0} color={getStatusColor}>
      {getStatusText()}
    </Status>
  ) : null;
};

export default TaskStatus;
