import React, { useContext } from 'react';
import Avatar from '../Header/Avatar';
import { MessageItem, MessageTime, MessageWrapper, TypographyMessage } from './styled';
import { SearchValueContext } from 'src/context/searchValue';
import { MessagesContext } from 'src/context/messages';
import { ChatsContext } from 'src/context/chats';
import { Stack } from '@mui/system';
import { ReactComponent as Read } from 'src/icons/read.svg';
import { ReactComponent as UnRead } from 'src/icons/unread.svg';

import { TMessageItem, TypeMessage } from 'src/api/messages/models';
import { Typography } from '@mui/material';
import { SystemMessage } from './SystemMessage';
import { ChatType } from 'src/api/common/models';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { TasksFactory } from './Task/TasksFactory';
import TaskStatus from './Task/TaskStatus';
import { formatTime, formatUTCDate } from 'src/utils/dateUtils';

type MessageProps = {
  item: TMessageItem;
  typeActiveChat: ChatType;
  withNewDay: boolean;
};

export const Message = React.forwardRef<HTMLDivElement, MessageProps>(({ item, typeActiveChat, withNewDay }, ref) => {
  const {
    body,
    messageType,
    author,
    created,
    isRead,
    fromCurrentModule,
    assignmentId,
    status,
    actor,
    isSystem,
    avatarUrl,
  } = item;

  const { messages, lengthMessageSearch } = useContext(MessagesContext);
  const { searchValue, searchValueConversation } = useContext(SearchValueContext);
  const { searchedMessage, activeChat, chats } = useContext(ChatsContext);
  const timeMessage = formatTime(created);
  const size = useWindowSize();

  const isTask = messageType === TypeMessage.Assignment;
  const currentChat = chats.find(chat => chat.id === activeChat);

  const fieldRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (searchValueConversation) {
      fieldRef.current?.scrollIntoView({ block: 'center' });
      return;
    }
    if (fieldRef.current && messages.length <= lengthMessageSearch) {
      fieldRef.current.scrollIntoView({ block: 'center' });
    }
  }, [messages, searchedMessage, searchValue, lengthMessageSearch, searchValueConversation]);
  return (
    <>
      {withNewDay && <SystemMessage text={formatUTCDate(created)} />}
      {isSystem ? (
        <SystemMessage text={body} ref={ref} />
      ) : (
        <MessageWrapper
          animation={searchedMessage?.length > 0 && created.startsWith(searchedMessage)}
          module={fromCurrentModule ? 1 : 0}
          ref={searchedMessage && created.startsWith(searchedMessage) ? fieldRef : null}
        >
          <Stack
            direction="row"
            justifyContent={fromCurrentModule ? 'end' : 'start'}
            sx={{ wrap: 'nowrap', flexBasis: '660px' }}
          >
            {!size.isMobileSize && !fromCurrentModule ? (
              <Avatar name={author || currentChat?.name} src={avatarUrl} />
            ) : null}
            <Stack sx={{ flexBasis: `${size.isTabletSize ? '300px' : '572px'}` }}>
              <MessageItem
                ref={ref}
                module={fromCurrentModule ? 1 : 0}
                read={isRead}
                typeMessage={messageType}
                status={status}
              >
                {isTask && assignmentId ? (
                  <TasksFactory task={item} isTablet={size.isTabletSize} isMobile={size.isMobileSize} />
                ) : (
                  <Stack gap="4px">
                    {typeActiveChat === ChatType.Laboratory && (
                      <Typography
                        variant="body16Bold"
                        lineHeight="24px"
                        sx={{ textTransform: 'capitalize' }}
                        color="grey.700"
                      >
                        {author.toLowerCase()}
                      </Typography>
                    )}
                    <div style={{ display: 'inline-flex', alignItems: 'end' }}>
                      <TypographyMessage color={fromCurrentModule ? 'grey.500' : 'grey.700'}>{body}</TypographyMessage>
                    </div>
                  </Stack>
                )}
                <MessageTime read={isRead} module={fromCurrentModule ? 1 : 0} isTask={isTask ? 1 : 0}>
                  {timeMessage}
                  {fromCurrentModule ? isRead ? <Read /> : <UnRead /> : null}
                </MessageTime>
              </MessageItem>
              {isTask && !size.isMobileSize && (
                <TaskStatus
                  status={status}
                  fromCurrentModule={fromCurrentModule}
                  actor={actor}
                  isTablet={size.isTabletSize}
                  isMobile={size.isMobileSize}
                />
              )}
            </Stack>
            {!size.isMobileSize && fromCurrentModule ? (
              <Avatar name={author || currentChat?.name} src={avatarUrl} />
            ) : null}
          </Stack>
        </MessageWrapper>
      )}
    </>
  );
});
