import { useEffect, useState, useContext } from 'react';
import { closeConnection, createConnection } from '../api/connection/connection';
import { chatsActions, ChatsContext } from '../context/chats';
import { messagesActions } from '../context/messages';
import { Client } from '@stomp/stompjs';
import { TCreateConnection } from '../api/connection/models';
import { ContentTypeMessageQueue, TMessageQueue } from '../api/messages/models';
import { portalActions, PortalContext } from '../context/portal';
import { SearchValueContext } from '../context/searchValue';

export function useConnection() {
  const { searchValue } = useContext(SearchValueContext);
  const { activeChat, chats } = useContext(ChatsContext);
  const { patientId, patientName, isUnmount, labCode, anchor, conversationId } = useContext(PortalContext);

  const [connectionConfigure, setConnectionConfigure] = useState<TCreateConnection | null>(null);
  const [messageGet, setGetMessage] = useState<TMessageQueue>();
  const [clientSubscribe, setClientSubsribe] = useState<any>();
  useEffect(() => {
    const create = async () => {
      const config = await createConnection();
      setConnectionConfigure(config.data);
    };
    void create();
  }, []);

  useEffect(() => {
    if (connectionConfigure) {
      const { connectionId, wssUrl, user, password, virtualHost, queue, expired } = connectionConfigure;
      const client: Client = new Client({
        reconnectDelay: 200,
        brokerURL: wssUrl,
        connectHeaders: {
          login: user,
          passcode: password,
          host: virtualHost,
        },

        onConnect() {
          const sub = client.subscribe(
            `/queue/${queue}`,
            message => {
              const messageParse: TMessageQueue = JSON.parse(message.body);
              setGetMessage(messageParse);
            },
            { 'x-expires': expired },
          );
          setClientSubsribe(sub);
        },
      });

      if (!isUnmount) {
        client.activate();
      }

      if (isUnmount) {
        client.configure({
          reconnectDelay: 0,
        });
        void client.deactivate();
        clientSubscribe?.unsubscribe(`/queue/${queue}`);
        closeConnection({
          id: connectionId,
        }).catch(error => console.log(error));
        setConnectionConfigure(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionConfigure, isUnmount]);

  useEffect(() => {
    // Обработка приходящих по очереди сообщений
    if (messageGet) {
      const newMessage = messageGet.content;
      const newMessageInCurrentChat = newMessage?.conversationId === activeChat;

      switch (messageGet.contentType) {
        // Сообщение о новом сообщении
        case ContentTypeMessageQueue.NewMessage: {
          if (newMessage) {
            const chatAlreadyCreated = chats.find(chat => chat.id === newMessage.conversationId);
            if (newMessageInCurrentChat && messageGet) {
              if (patientId && labCode && anchor && conversationId) portalActions?.resetParams();
              messagesActions?.getMessage(newMessage, searchValue, activeChat);
              chatsActions?.setCreatedActiveChat(newMessage.created);
            }
            if (chatAlreadyCreated) {
              chatsActions?.changeChatList(newMessage, messageGet.contentType);
            } else {
              chatsActions?.addChatWitnPatient(newMessage, patientName, patientId);
              chatsActions?.setCreatedActiveChat(newMessage.created);
              chatsActions?.setActiveChat(newMessage.conversationId);
              chatsActions?.setActiveChatLabCode('');
            }
          }
          break;
        }
        // Сообщение о прочтении
        case ContentTypeMessageQueue.ReadMessages: {
          if (newMessage) chatsActions?.changeChatList(newMessage, messageGet.contentType, newMessage.ids.length);
          if (newMessageInCurrentChat) {
            messagesActions.readMessagesActiveChat(newMessage.ids);
          }
          break;
        }
        // Сообщение об изменении статуса задачи
        case ContentTypeMessageQueue.AssignmentStatusChanged: {
          if (newMessageInCurrentChat) {
            messagesActions.changeTaskStatus(newMessage.status, newMessage.assignmentId, newMessage.actor);
            chatsActions?.changeChatList(newMessage, messageGet.contentType);
          }
          break;
        }
        default: {
          throw new Error('Error message get');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageGet]);
}
