import React, { useContext, useEffect } from 'react';
import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { SearchBlock, UnreadSwitchBlock, ChatList, Dialog } from '../index';
import { border } from 'src/const';
import { useConnection } from 'src/hooks/useConnection';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { chatsActions, ChatsContext } from 'src/context/chats';
import { ReactComponent as Back } from 'src/icons/back.svg';
import { profileActions } from 'src/context/profile';

const classes = {
  box_wrapper: {
    backgroundColor: 'background.paper',
    border: border,
    height: '100%',
  } as SxProps,
  back_button: {
    color: 'grey.700',
    fontSize: '16px',
    lineHeight: '24px',
    height: '24px',
    width: '70px',
    marginBottom: '18px',
  } as SxProps,
};

const handleBackClick = () => {
  chatsActions.setActiveChat('');
  chatsActions?.setActiveChatLabCode('');
};

export const Main: React.FC = () => {
  const size = useWindowSize();
  const { activeChat } = useContext(ChatsContext);

  useConnection();

  useEffect(() => {
    profileActions?.getProfilePermissions();
  }, []);

  return !size.isMobileSize ? (
    <>
      <Typography color="grey.800" variant="head32Bold">
        Messages
      </Typography>
      <Grid2
        container
        spacing={0}
        sx={{
          flexWrap: 'nowrap',
          marginTop: '24px',
          height: 'calc(100vh - 208px)',
        }}
      >
        <Grid2
          sx={{
            height: '100%',
            minWidth: `${size.isTabletSize ? '242px' : '372px'}`,
            maxWidth: `${size.isTabletSize ? '242px' : ''}`,
          }}
        >
          <Stack
            sx={{
              ...classes.box_wrapper,
            }}
          >
            <SearchBlock isTablet={size.isTabletSize} />
            <ChatList />
            {!size.isTabletSize && <UnreadSwitchBlock />}
          </Stack>
        </Grid2>
        <Grid2
          sx={{
            height: '100%',
            minWidth: '456px',
            maxWidth: '1411px',
            width: '1411px',
          }}
        >
          <Box
            sx={{
              ...classes.box_wrapper,
              borderLeft: 'none',
            }}
          >
            <Dialog />
          </Box>
        </Grid2>
      </Grid2>
    </>
  ) : (
    <>
      {!activeChat && (
        <Typography color="grey.800" variant="head32Bold" marginBottom="24px">
          Messages
        </Typography>
      )}
      {activeChat && (
        <Button startIcon={<Back />} variant="text" onClick={handleBackClick} sx={classes.back_button}>
          Back
        </Button>
      )}
      <Grid2
        container
        spacing={0}
        sx={{
          flexWrap: 'nowrap',
          height: `calc(${window.innerHeight}px - ${activeChat ? '180px' : '198px'})`,
          width: '100%',
        }}
      >
        <Grid2
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <Stack
            sx={{
              ...classes.box_wrapper,
            }}
          >
            {!activeChat ? (
              <>
                <SearchBlock isMobile />
                <ChatList />
              </>
            ) : (
              <Dialog />
            )}
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};
