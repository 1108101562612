import { AssignmentStatus, TAssignment } from '../assignments/models';
import { ChatType } from '../common/models';

export type TConversationItem = {
  id: string;
  name: string;
  lastMessage: TMessageItem;
  unreadCount: number;
  updated: string;
  patientId?: string;
  conversationType: ChatType;
  laboratoryCode?: string;
  conversationAvatarUrl?: string;
};

export type TMessageItem = {
  messageType: TypeMessage.Assignment | TypeMessage.Text;
  body: string;
  createdBy: string;
  updatedBy?: string;
  id?: string;
  updated?: string;
  created: string;
  isRead: boolean;
  conversationId: string;
  author: string;
  fromCurrentUser: boolean;
  fromCurrentModule: boolean;
  status?: AssignmentStatus;
  assignmentType?: TAssignment;
  primaryDiagnosis?: string;
  secondariesDiagnoses?: string[];
  orderingProvider?: string;
  originalOrderNumber?: string;
  resultId?: string;
  sampleId?: string;
  assignmentId?: string;
  tests?: string[];
  actor?: string;
  billType?: TBillType;
  title?: string;
  messageLink: string;
  tags: TTags[];
  isSystem?: boolean;
  conversation?: TConversationItem;
  avatarUrl?: string;
};

export enum TBillType {
  Client = 'Client',
  Patient = 'Patient',
  Insurance = 'Insurance',
}

export enum TTags {
  Billing = 'Billing',
  ClericalOrGeneral = 'ClericalOrGeneral',
  Medical = 'Medical',
}

export type TCreateChatPatient = {
  contactId?: string;
};

export type TSendMessage = {
  Body: string;
  conversationId?: string;
  contactId?: string;
  tags?: TTags[];
};

export type TScrollMessageValue = {
  conversationId: string;
  anchor: string;
  takeBefore?: number;
  takeAfter?: number;
};

export enum TypeMessage {
  Text = 'Text',
  Assignment = 'Assignment',
}

export type TScrollMessage = {
  isSuccess: boolean;
  isFileResult: boolean;
  ok: TMessageItem[];
  error: null;
  fileDto: null;
};

export type TMessageQueue = {
  contentType?: ContentTypeMessageQueue;
  content?: TMessageItem & { ConversationId: string; ids: string[] };
};

export enum ContentTypeMessageQueue {
  NewMessage = 'NewMessage',
  ReadMessages = 'ReadMessages',
  AssignmentStatusChanged = 'AssignmentStatusChanged',
}
