import { TAKE_PAGER_OPTIONS } from '../../../const';

const billingTypeOptions = [
  {
    label: 'Client',
    value: 'Client',
  },
  {
    label: 'Patient',
    value: 'Patient',
  },
  {
    label: 'Insurance',
    value: 'Insurance',
  },
];
const requiredMessage = 'field must be filled';

const filter = {
  search: '',
  page: {
    number: 0,
    size: TAKE_PAGER_OPTIONS,
  },
};

const defaultValues = {
  message: '',
  originalOrderNumber: '',
  tests: [],
  primaryDiagnosis: '',
  secondaryDiagnoses: [],
  diagnoses: [],
  orderingProvider: '',
  billType: '',
};

const MAX_MESSAGE_LENGTH = 500;

export { billingTypeOptions, requiredMessage, filter, defaultValues, MAX_MESSAGE_LENGTH };
