import { Control, Controller, Message } from 'react-hook-form';
import { billingTypeOptions } from '../consts';
import { Stack, Select, Typography, FormHelperText, FormControlLabel } from '@mui/material';
import { MenuItemOptions } from '../styled';
import { ReactComponent as IconArrow } from 'src/icons/chevron-down.svg';
import { classes } from './FormContolAutocomplete';
import { FC } from 'react';
import { Label } from 'src/components/ui';

interface SelectBillingTypeProps {
  control: Control<any, any>;
  error?: Message;
}
export const SelectBillingType: FC<SelectBillingTypeProps> = ({ control, error }) => {
  return (
    <Controller
      name="billType"
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          label={<Label label="Billing Type" />}
          labelPlacement="top"
          sx={{ width: '100%', gap: '3px', alignItems: 'start', margin: 0 }}
          control={
            <Stack>
              <Select
                sx={{ ...classes.input, '& ul': { padding: 0 } }}
                fullWidth
                color="secondary"
                IconComponent={IconArrow}
                value={value}
                variant="outlined"
                displayEmpty
                onChange={onChange}
                MenuProps={{
                  keepMounted: true,
                  disablePortal: true,
                  MenuListProps: { disablePadding: true },
                  sx: {
                    position: 'absolute',
                    zIndex: 1300,
                    inset: '0px auto auto 0px',
                    width: '352px',
                    height: '200px',
                    transform: 'translate3d(0px, 42px, 0px)',
                    '& .MuiPopover-paper': {
                      top: '0 !important',
                      left: '0 !important',
                    },
                  },
                }}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return (
                      <Typography variant="label14Medium" color="grey.400">
                        Select
                      </Typography>
                    );
                  }
                  return (
                    <Typography variant="label14Medium" color="grey.700">
                      {selected}
                    </Typography>
                  );
                }}
              >
                {billingTypeOptions.map(type => (
                  <MenuItemOptions key={type.value} value={type.value} color="secondary">
                    {type.label}
                  </MenuItemOptions>
                ))}
              </Select>
              {error && <FormHelperText sx={{ color: '#d32f2f' }}>{error as unknown as string}</FormHelperText>}
            </Stack>
          }
        />
      )}
    />
  );
};
