import styled from '@emotion/styled';
import { Button, FormControlLabel, styled as styledMui, Typography } from '@mui/material';

import { TypeMessage } from 'src/api/messages/models';
import { AssignmentStatus } from 'src/api/assignments/models';
import { ChatType } from 'src/api/common/models';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/const';
import { theme } from 'src/theme';
import { hexOpacity } from 'src/utils/hexOpacity';
import { Stack } from '@mui/system';

type MessageItemType = {
  read: boolean;
  typeMessage: TypeMessage.Assignment | TypeMessage.Text;
  module: number;
  status?: string | undefined;
};

const backgroundColor = (props: MessageItemType) => {
  const { module, status, typeMessage } = props;
  if (typeMessage === TypeMessage.Assignment && status) {
    return status === AssignmentStatus.Incomplete
      ? theme.palette.warning.light
      : status === AssignmentStatus.Completed
      ? theme.palette.success.light
      : theme.palette.error.light;
  }
  if (typeMessage === TypeMessage.Text) {
    return module ? hexOpacity(theme.palette.secondary.main, '7%') : 'transparent';
  }
};

type MessageTimeAndWrapperType = {
  read: boolean;
  module: number;
  isTask: number;
};

type MessageWrapperType = {
  animation: boolean;
  module: number;
};

type ChatWrapperType = {
  typeActiveChat: ChatType;
};

const MessageItem = styled.div<MessageItemType>`
  background: ${props => backgroundColor(props)};
  padding: ${props => (props.typeMessage === TypeMessage.Text ? '8px 16px' : '12px 20px')};
  border: ${props =>
    !props.module && props.typeMessage === TypeMessage.Text
      ? `1px solid ${hexOpacity(theme.palette.secondary.main, '14%')}`
      : ''};
  margin: 0 12px;
  position: relative;
  font-size: 16px;
  scroll-margin-bottom: 20px;
  color: ${props =>
    props.module && props.typeMessage === TypeMessage.Text ? theme.palette.grey[500] : theme.palette.grey[700]};
  overflow-wrap: break-word;
  & a {
    color: ${theme.palette.secondary.main};
    text-decoration: none;
  }
  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin: ${props => (props.module ? '0 16px 0 0' : '0 0 0 16px')};
    padding: 12px 24px 20px 24px;
    & p {
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
  }
  @media (max-width: ${MAX_MOBILE_WIDTH}px) {
    padding: 12px 12px 18px 12px;
    font-size: 14px;
    margin: ${props => (props.module ? '0 0 0 12px' : '0 12px 0 0')};
    & p {
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
  }
`;

const MessageTime = styled.div<MessageTimeAndWrapperType>`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.palette.grey[400]};
  position: relative;

  display: flex;
  justify-content: end;
  align-items: center;
  flex-shrink: 0;
`;

const MessageWrapper = styled.div<MessageWrapperType>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  justify-content: ${props => (props.module ? 'end' : 'start')};
  margin-bottom: 20px;
  animation: ${props => (props.animation ? '3s blinker' : '')};
  animation-fill-mode: forwards;
  @keyframes blinker {
    from {
      background: #eef5f8;
    }
    to {
      backgrond: transparent;
    }
  }
`;

const ChatWrapper = styled.div<ChatWrapperType>`
  padding: 8px 36px;
  height: auto;
  position: relative;
  overflow: auto;
  flex-grow: 1;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${hexOpacity(theme.palette.secondary.main, '14%')};
  }
  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding: 6px 12px;
    height: 100%;
  }
`;

const FormControlLabelStyle = styledMui(FormControlLabel)`
display: block;
font-weight: 400;
font-size: 14px;
line-height: 20px;
`;

const TypographyMessage = styledMui(Typography)`
font-size: 14px;
font-weight: 500;
line-height: 20px;
@media (max-width: ${MAX_MOBILE_WIDTH}px) {
  font-size: 12px;
}
`;

const SystemBody = styled.div`
  min-height: 28px;
  padding: 5px 16px;
  background: #f3f4f6;
  opacity: 0.5;
  color: #858d9c;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  @media (max-width: ${MAX_MOBILE_WIDTH}px) {
    font-size: 12px;
    padding: 2px 8px;
  }
`;

const SystemMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const MessageConversationList = styledMui(Stack)`
&::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
&::-webkit-scrollbar-track {
  background-color: transparent;
}
&::-webkit-scrollbar-thumb {
  background: ${hexOpacity(theme.palette.secondary.main, '14%')};
}
overflow-Y: scroll;
max-height: 370px;
z-index: 5;
background: #fff;
`;

const HeaderSearchingMessages = styledMui(Stack)`
height: 42px;
background-color: #F3F4F6;
justify-content: space-between;
flex-direction: row;
position: fixed;
z-index: 5;
align-items: center;
min-width: 456px;
max-width: 1411px;
margin-left: -36px;
margin-top: -8px;
padding: 0 24px;
`;

const SearchedMessages = styledMui(Stack)`
position: fixed;
min-width: 456px;
max-width: 1403px;
margin-left: -36px;
margin-top: 34px;
z-index: 5;
`;

const ChevronButton = styled(Button)`
  width: 42px;
  height: 42px;
  padding: 9px;
  min-width: 0;

  &:hover {
    svg path {
      fill: none;
    }
  }

  &:active {
    svg path {
      fill: none;
    }
  }
`;

export {
  MessageItem,
  MessageTime,
  MessageWrapper,
  ChatWrapper,
  FormControlLabelStyle,
  TypographyMessage,
  SystemBody,
  SystemMessageWrapper,
  MessageConversationList,
  HeaderSearchingMessages,
  ChevronButton,
  SearchedMessages,
};
