export type TSendTaskMakeAnAppointment = {
  message?: string;
  conversationId: string;
  tags?: string[];
  actorId?: string;
};

export type TSendTaskAddOnTestRequest = {
  message: string;
  conversationId: string;
  channels?: Tags;
  originalOrderNumber: string;
  tests: string[];
  primaryDiagnosis?: string;
  secondariesDiagnoses: string[];
  orderingProvider: string;
};

export type Tags = 'billing' | 'clinical' | 'clerical';

export type TNewStatus = {
  assignmentId: string;
  conversationId: string;
  status: AssignmentStatus;
};

export enum AssignmentStatus {
  'Incomplete' = 'Incomplete',
  'Completed' = 'Completed',
  'Cancelled' = 'Cancelled',
}

export enum TAssignment {
  AddonTests = 'AddonTests',
  MakeAnAppointment = 'MakeAnAppointment',
  RejectionReasonSolving = 'RejectionReasonSolving',
  NoticeOfCriticalValues = 'NoticeOfCriticalValues',
}
