import { Dialog, DialogContent, FormControl, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import React, { useState, useContext } from 'react';
import { TSendTaskMakeAnAppointment } from 'src/api/assignments/models';
import { ChatsContext } from 'src/context/chats';
import { messagesActions } from 'src/context/messages';
import { ReactComponent as CloseIcon } from 'src/icons/close-lg.svg';
import { ReactComponent as IconArrow } from 'src/icons/chevron-down.svg';
import { ButtonCancel, ButtonSend } from '../styled';
import { tasksTypes } from './consts';
import { DialogActionsStyle, DialogTitleStyled, MenuItemStyled, selectStyle, TitleModal } from './styled';

type TaskModalProps = {
  isOpenTaskModal: boolean;
  setIsOpenTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  patientId: Nullable<string> | undefined;
};

export const TaskModal: React.FC<TaskModalProps> = ({ isOpenTaskModal, setIsOpenTaskModal, patientId }) => {
  const [assignmentType, setAssignmentType] = useState<string>('');
  const [messageValue, setMessageValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const { activeChat } = useContext(ChatsContext);

  const handleClose = () => {
    setIsOpenTaskModal(false);
    setMessageValue('');
    setAssignmentType('');
  };
  const handleChangeTask = (e: SelectChangeEvent<string>) => {
    setAssignmentType(e.target.value);
    setError(false);
  };
  const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => setMessageValue(e.target.value);
  const handleSendTask = () => {
    if (!assignmentType) setError(true);
    if (assignmentType) {
      const taskValue: TSendTaskMakeAnAppointment = {
        message: messageValue || undefined,
        conversationId: activeChat,
        actorId: patientId || '',
      };
      void messagesActions?.sendTaskPatient(taskValue);
      setMessageValue('');
      setAssignmentType('');
      setIsOpenTaskModal(false);
    }
  };

  return (
    <Dialog open={isOpenTaskModal} onClose={handleClose} fullWidth maxWidth="sm" disablePortal>
      <DialogTitleStyled>
        <TitleModal variant="body18Bold" lineHeight="24px" color="grey.700">
          Create a task
        </TitleModal>
        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
      </DialogTitleStyled>
      <DialogContent>
        <Typography variant="label14Bold" color="grey.700" lineHeight="24px">
          Task
        </Typography>
        <FormControl fullWidth error={error} sx={{ marginBottom: '36px' }}>
          <Select
            fullWidth
            variant="outlined"
            labelId="select-task"
            color="secondary"
            value={assignmentType}
            onChange={handleChangeTask}
            IconComponent={IconArrow}
            MenuProps={{ keepMounted: true, disablePortal: true, MenuListProps: { disablePadding: true } }}
            sx={selectStyle}
          >
            {tasksTypes.map(task => (
              <MenuItemStyled value={task.value} key={task.value}>
                {task.label}
              </MenuItemStyled>
            ))}
          </Select>
          {error && (
            <Typography color="errorInput.main" variant="label14Medium" height={0}>
              Field is required
            </Typography>
          )}
        </FormControl>
        <TextField
          multiline
          fullWidth
          rows={9.4}
          value={messageValue}
          onChange={handleChangeMessage}
          placeholder="Your message"
          InputProps={{
            style: {
              height: '212px',
              padding: '12px',
            },
          }}
        />
      </DialogContent>
      <DialogActionsStyle>
        <ButtonCancel color="secondary" variant="outlined" onClick={handleClose}>
          Cancel
        </ButtonCancel>
        <ButtonSend color="secondary" variant="contained" onClick={handleSendTask} autoFocus>
          Send
        </ButtonSend>
      </DialogActionsStyle>
    </Dialog>
  );
};
